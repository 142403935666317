import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Otherdsmodeluser } from '../models/otherds-user.model';
const API_URL_test = 'http://localhost:3003/api/otherdsuser/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/otherdsuser/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class OtherdsServiceUser {
  Otherdssnonpaye: Otherdsmodeluser[];
  Otherdsspaye: Otherdsmodeluser[];
  Otherdssmarquepaye: Otherdsmodeluser[];  
    constructor(private http: HttpClient) { }
    public otherdss: Otherdsmodeluser[] = [];
    public otherdss$ = new Subject<Otherdsmodeluser[]>();
    public otherdssnonaffecte$ = new Subject<Otherdsmodeluser[]>();
    public otherdssencours$ = new Subject<Otherdsmodeluser[]>();
    public otherdssactif$ = new Subject<Otherdsmodeluser[]>();
    public otherdsscloture$ = new Subject<Otherdsmodeluser[]>();
    public otherdssnonpaye$ = new Subject<Otherdsmodeluser[]>();
    public otherdsspaye$ = new Subject<Otherdsmodeluser[]>();
    public otherdssmarquepaye$ = new Subject<Otherdsmodeluser[]>();

    getOtherdsalldata() {
        this.http.get(API_URL_cloud).subscribe(
          (otherdss: Otherdsmodeluser[]) => {
            if (otherdss) {
              this.otherdss = otherdss;
              this.emitOtherdss();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      getOtherdspasencoreaffecte() {
        this.http.get(API_URL_cloud+'/filter/'+'nonaffecte/').subscribe(
          (Otherdss: Otherdsmodeluser[]) => {
            if (Otherdss) {
             //this.Otherdss = Otherdss;
              this.emitOtherdssnonaffecte();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }  
  getOtherdsencours() {
        this.http.get(API_URL_cloud+'/filter/'+'encours/').subscribe(
          (Otherdss: Otherdsmodeluser[]) => {
            if (Otherdss) {
             // this.Otherdss = Otherdss;
              this.emitOtherdssencours();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getOtherdsactif() {
        this.http.get(API_URL_cloud+'/filter/'+'actif/').subscribe(
          (Otherdss: Otherdsmodeluser[]) => {
            if (Otherdss) {
             // this.Otherdss = Otherdss;
              this.emitOtherdssactif();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getOtherdscloture() {
        this.http.get(API_URL_cloud+'/filter/'+'cloture/').subscribe(
          (Otherdss: Otherdsmodeluser[]) => {
            if (Otherdss) {
             // this.Otherdss = Otherdss;
              this.emitOtherdsscloture();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      getOtherdsnonpaye() {
        this.http.get(API_URL_cloud+'/filter/'+'nonpaye/').subscribe(
          (Otherdss: Otherdsmodeluser[]) => {
            if (Otherdss) {
           //   console.log(Otherdss)
             this.Otherdssnonpaye = Otherdss;
              this.emitOtherdssnonpaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getOtherdspaye() {
        this.http.get(API_URL_cloud+'/filter/'+'paye/').subscribe(
          (Otherdss: Otherdsmodeluser[]) => {
            if (Otherdss) {
             this.Otherdsspaye = Otherdss;
              this.emitOtherdsspaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getOtherdsmarquepaye() {
        this.http.get(API_URL_cloud+'/filter/'+'marquepaye/').subscribe(
          (Otherdss: Otherdsmodeluser[]) => {
            if (Otherdss) {
             this.Otherdssmarquepaye = Otherdss;
              this.emitOtherdssmarquepaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
      emitOtherdss() {
        this.otherdss$.next(this.otherdss);
      }
      emitOtherdssactif() {
        this.otherdssactif$.next(this.otherdss);
      }
  emitOtherdsscloture() {
        this.otherdsscloture$.next(this.otherdss);
      }
      emitOtherdssnonaffecte() {
        this.otherdssnonaffecte$.next(this.otherdss);
      }
  emitOtherdssencours() {
        this.otherdssencours$.next(this.otherdss);
      }
      emitOtherdssnonpaye() {
        this.otherdssnonpaye$.next(this.Otherdssnonpaye);
      }
  emitOtherdsspaye() {
        this.otherdsspaye$.next(this.Otherdsspaye);
      }
  emitOtherdssmarquepaye() {
        this.otherdssmarquepaye$.next(this.Otherdssmarquepaye);
      }
      getOtherdsalldatawithoutsubscription() {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getOtherdsdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      getOtherdsdataByuser(client: string) {
        this.http.get(API_URL_cloud+'client/'+client).subscribe(
            (otherdss: Otherdsmodeluser[]) => {
              if (otherdss) {
                this.otherdss = otherdss;
                this.emitOtherdss();
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
      getOtherdsdataByuserwithoutsubscription(client: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud+'client/'+client).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
      create(otherds: Otherdsmodeluser) {
        return new Promise((resolve, reject) => {
          
          this.http.post(API_URL_cloud, otherds).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
      modifyotherdsById(id: string, otherds: Otherdsmodeluser) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+ id, otherds).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      modifyotherdsByIdforuser(id: string, otherds: Otherdsmodeluser) {
        return new Promise((resolve, reject) => {
          this.http.put(API_URL_cloud+'modify/'+ id, otherds).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      deleteotherdsdataById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      } 
      
      
    }
