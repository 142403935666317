import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { interval, Subscription } from 'rxjs';
import { MustMatch } from '../_helpers/must-match.validator';
import { AlertService } from '../_helpers/alert.service';
import Swal from 'sweetalert2';
import { DeccomptabiliteService } from '../services/dec-comptabilite';
import { Decfiscmens } from '../models/dec-fisc-mens';
import { Deccomptabilite } from '../models/dec-comptabilite';
import { DecfiscmensService } from '../services/dec-fisc-mens';
import { Sort } from '../_helpers/sort';
import { CommunService } from '../services/commun';
import { OTPService } from '../services/otp-table.service';
import { ContactService } from '../services/contact.service';
import { Contact } from '../models/contact.model';
import { read, utils } from "xlsx"

@Component({
  selector: 'app-modify-user-admin',
  templateUrl: './modify-user-admin.component.html',
  styleUrls: ['./modify-user-admin.component.scss']
})

export class ModifyUserAdminComponent implements OnInit {
  uploadFiles: File[] = [];
  fiscaltvaassoblis:any[]=["Assujeti obligatoire", "Assujeti sur option", "Assujeti partiel", "Non assujeti", "Assujeti partiel sur option"];
  fiscaltvaassoblisassociation:any[]=["Assujeti obligatoire", "Assujeti sur option", "Assujeti partiel", "Non assujeti", "Assujeti partiel sur option"];
  fiscaltvaassoblisliberale:any[]=["Assujeti obligatoire"];
  natureactivites: any[]=["Profession Libérale","société","association et syndic","Commerçant","Artisan","Salarié ou Pensionnaire"];
  natureactivitesphysique: any[]=["Profession Libérale","Commerçant","Artisan","Salarié ou Pensionnaire"];
  natureactivitesmorale: any[]=["association et syndic","société"];
  activites: any[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
  "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire",/*"Huissiers  (de justice)"*/, "Interprètes",
  "Ingénieurs-conseil","Topographes","Syndic des copropriétaires","Ventes en gros","Ventes en détail","Pharmacie","Consommation sur place","Batiment et travaux annexes","Réparation automobiles","Autre"
  ,"Menuiserie","Commerciale","Industrielle","Service","services liées aux manifestations et cérémonies"];
  sousactivites: any[]=["Avocat","Avocat à la cour d'appel","Avocat à la cour de cassation","Médecin","Médecin spécialiste","Médecin dentiste","Médecin vétérinaire"
  ,"pièces auto","produits dives","étagères métalliques","Fruits secs","Pnematique","Café","Restaurant","Plomberie et climatisation","Electricité Batiment","Maçonnerie","Mécanique Auto","Tolerie"
  ,"Electricité Auto","Menuiserie Bois","Menuiserie Aluminium","Commerce international","Fabrication de produits chimiques","Activités informatiques","Consultant","Agence de voyage",
  "Agence de publicité","Information et promotion médicale et scientifique","Organisation des séminaires","Organisation des évènements","Construction métallique","Transport pour le compte d'autrui"];
  specialites: any[]=["Chirurgie générale",
  "Chirurgie pédiatrique",
  "Chirurgie carcinologique",
  "Chirurgie cardio-vasculaire",
  "Chirurgie vasculaire périphérique",
  "Chirurgie neurologique",
  "Chirurgie orthopédique et traumatologique",
  "Chirurgie plastique, réparatrice et esthétique",
  "Chirurgie urologique",
  "Gynéco-obstétrique",
  "ORL",
  "Stomatologie et chirurgie maxillo-faciale",
  "Ophtalmologie",
  "Chirurgie thoracique",
  "Anesthésie réanimation",
  "Psychiatrie",
  "Pédo-psychiatrie",
  "Imagerie médicale",
  "Anatomie",
  "Anatomie et cytologie pathologiques",
  "Carcinologie médicale",
  "Cardiologie",
  "Dermatologie",
  "Endocrinologie",
  "Gastro-entérologie",
  "Hématologie clinique",
  "Maladies infectieuses",
  "Médecine d’urgence",
  "Médecine de travail",
  "Médecine interne",
  "Médecine légale",
  "Médecine physique, rééducation et réadaptation fonctionnelle",
  "Médecine préventive et communautaire",
  "Néphrologie",
  "Neurologie",
  "Nutrition et maladies nutritionnelles",
  "Pédiatrie",
  "Pneumologie",
  "Radiothérapie carcinologique",
  "Réanimation médicale",
  "Rhumatologie",
  "Biophysique et médecine nucléaire",
  "Génétique",
  "Biologie médicale option biochimie",
  "Biologie médicale option hématologie",
  "Biologie médicale option parasitologie",
  "Biologie médicale option microbiologie",
  "Biologie médicale option immunologie",
  "Histo-embryologie",
  "Pharmacologie",
  "Physiologie et explorations fonctionnelles","Service de livraison","Transport de marchandises"];
  sousspecialites: any[]=[];
  sousactivitesavocat: string[]=["Avocat","Avocat à la cour d'appel","Avocat à la cour de cassation"];
  sousactivitesventeengros: string[]=["pièces auto","produits divers","étagères métalliques","Fruits secs","Pnematique","Produits de quincaillerie"];
  sousactivitesventeendetail: string[]=["pièces auto","produits divers","étagères métalliques","Fruits secs","Pnematique","Produits de quincaillerie"];
  sousactivitesconsommationsurplace: string[]=["Café","Restaurant"];
  sousactivitesbatimentettravauxannexe: string[]=["Plomberie et climatisation","Electricité Batiment","Maçonnerie"];
  sousactivitesreparationautomobile: string[]=["Mécanique Auto","Tolerie","Electricité Auto"];
  sousactivitesmenuiserie: string[]=["Menuiserie Bois","Menuiserie Aluminium"];
  sousactivitescommerciale: string[]=["Commerce international"];
  sousactivitesindustrielle: string[]=["Fabrication de produits chimiques","Construction métallique"];
  sousactivitesservice: string[]=["Consultant","Activités informatiques","Agence de voyage","Agence de publicité","Information et promotion médicale et scientifique","Organisation des séminaires","Organisation des évènements","Transport pour le compte d'autrui"];
  sousactivitesmedecin: string[]=["Médecin","Médecin spécialiste","Médecin dentiste","Médecin vétérinaire"];
  sousactivitesmanifestation: string[]=["photographe"];
  specialitesmedecinspecialiste: string[]=["Chirurgie générale",
  "Chirurgie pédiatrique",
  "Chirurgie carcinologique",
  "Chirurgie cardio-vasculaire",
  "Chirurgie vasculaire périphérique",
  "Chirurgie neurologique",
  "Chirurgie orthopédique et traumatologique",
  "Chirurgie plastique, réparatrice et esthétique",
  "Chirurgie urologique",
  "Gynéco-obstétrique",
  "ORL",
  "Stomatologie et chirurgie maxillo-faciale",
  "Ophtalmologie",
  "Chirurgie thoracique",
  "Anesthésie réanimation",
  "Psychiatrie",
  "Pédo-psychiatrie",
  "Imagerie médicale",
  "Anatomie",
  "Anatomie et cytologie pathologiques",
  "Carcinologie médicale",
  "Cardiologie",
  "Dermatologie",
  "Endocrinologie",
  "Gastro-entérologie",
  "Hématologie clinique",
  "Maladies infectieuses",
  "Médecine d’urgence",
  "Médecine de travail",
  "Médecine interne",
  "Médecine légale",
  "Médecine physique, rééducation et réadaptation fonctionnelle",
  "Médecine préventive et communautaire",
  "Néphrologie",
  "Neurologie",
  "Nutrition et maladies nutritionnelles",
  "Pédiatrie",
  "Pneumologie",
  "Radiothérapie carcinologique",
  "Réanimation médicale",
  "Rhumatologie",
  "Biophysique et médecine nucléaire",
  "Génétique",
  "Biologie médicale option biochimie",
  "Biologie médicale option hématologie",
  "Biologie médicale option parasitologie",
  "Biologie médicale option microbiologie",
  "Biologie médicale option immunologie",
  "Histo-embryologie",
  "Pharmacologie",
  "Physiologie et explorations fonctionnelles"];
  specialitestransport: string[]=["Service de livraison","Transport de marchandises"]
  selected: any;
  activitesassociation: string[];
  activitescommercant: string[]=["Ventes en gros","Ventes en détail","Pharmacie","Consommation sur place"]
  activitesartisan: string[]=["Batiment et travaux annexes","Réparation automobiles","Menuiserie","services liées aux manifestations et cérémonies"]
  activitesassociations: any[]=["Syndic des copropriétaires"/*,"Association"*/];
  activitesliberales: string[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
  "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire",/*"Huissiers  (de justice)"*/, "Interprètes",
  "Ingénieurs-conseil","Topographes","Autre"];
  activitessociete: string[]=["Commerciale","Industrielle","Service"];
  public userForm: FormGroup;
  public users: User[]=[];
  public type: any[]=["recette journaliere","edition note honoraire","achat","releve manuel","releve attache","salaires"];
  public taux: any[]=["7","13","19","exonere","export","suspension","fodec","tpe"];
  public fournisseurs: any[]=[];
  public natures: any[]=[];
  public clients: any[]=[];
  public numeros: any[]=[];
  public banques: any[]=[];
  public naturemanuel: any[]=[];
  public codeValue: string;
  public secteurValue: string;
  public roleValue: string;
  public user: User;
  public loading = false;
  private usersSub: Subscription;
  errormsg:string;
  editionnoteform: FormGroup;
  releveform: FormGroup;
  recetteform: FormGroup;
  achatform: FormGroup;
  ammounts:FormArray
  ammounts1:FormArray
  ammounts10:FormArray

  ammounts2:FormArray
  ammounts3:FormArray
  ammounts11:FormArray

  ammounts4:FormArray
  ammounts5:FormArray
  ammounts6:FormArray
  ammounts7:FormArray
  ammounts8:FormArray
  ammounts0:FormArray
  ammounts9:FormArray
  token: any;
  deccomptabilitesSub: Subscription;
  decfiscmensSub: Subscription;

  deccomptabilites: import("../models/dec-comptabilite").Deccomptabilite[]=[];
  decfiscmenss: import("../models/dec-fisc-mens").Decfiscmens[]=[]

  settedclients: any[]=[];
  settednatures: any[]=[];
  settedfournisseurs: any[]=[];
  settednumeros: any[]=[];
  settedbanques: any[]=[];
  settednaturereleve: any[]=[];
  objetsdebit: any[]=[];
  objetscredit: any[]=[];
  settedobjetsdebit: any[]=[];
  settedobjetscredit: any[]=[];
  settedobjets:any[]=[];
  copobjets:any[]=[];
  copnatures:any[]=[];
  copnaturesnon:any[]=[];

  reglements:any[]=[];

  settedcopobjets:any[]=[];
  settedcopnatures:any[]=[];
  settedcopnaturesnon:any[]=[];

  settedreglements:any[]=[];

  dosspasencorevalide: any[]=[];
  sorteddosspasencorevalide: any[]=[];
  filtredusers: any=[];
  prenom: any;
  nom: any;
  filtredusers2: any=[];
  prenomfisc: any;
  nomfisc: any;
  prenomaffecte: any;
  nomaffecte: any;
  private intervalSub: Subscription;
  countdown=0;
  option1Value: boolean;
  optionValue: boolean;
  beneficiaires: any[]=[];
  settedbeneficiaires:any[]=[];
  firsttimer: number;
  showparamcomptable=false;
  fiscalmatPattern = "^[0-9]{7}$";
  fiscalmatletterPattern="^[A-Z]{1}$";
  fiscalmatnumbersPattern="^[0-9]{3}$";
  fiscalmatinchangedletterPattern = "^[A-Z]{1}$";
  fiscalmatsecondinchangedletterPattern = "^[A-Z]{1}$";
  codepostalPattern: "^[a-zA-Z0-9]+{4}$"
  cifUploaded = false;
  diUploaded = false;
  natsociete=false;
  tot=false
  sal=false
  mat=false
  nontot=false
  tvaafficheform: FormGroup;
  ammounts12:FormArray
  emailverified=false
mobileverified=false
copform: FormGroup;
public ammounts13: FormArray;
  file: any;
  uploadEvent: any;
  arrayBuffer: string | ArrayBuffer;
  exceljsondata2: unknown[]=[];
  selectedTabIndex=0;
  selectedTabIndexsection=0;
  selectedTabIndexsignaletique=0;
  displayedColumns: string[] = ['prenom', 'nom', 'mois', 'annee', 'type', 'origine', 'created', 'updated', 'confirmation'];
  resendemailotp=false
  resendmobileotp=false
  constructor(private formBuilder: FormBuilder,
    private userservice: UserService,
    private route: ActivatedRoute,
    private router: Router,private commun: CommunService,
    private deccompt: DeccomptabiliteService,
    private decfiscmens: DecfiscmensService,
    private auth: AuthService,private otp: OTPService,private cont: ContactService,
    private alertService: AlertService) {
      this.editionnoteform = this.formBuilder.group({
        journal:'',
        journalnon:'',
        cptedt:'',
        total:'',
        journalcop:'',
        ammounts: this.formBuilder.array([ this.createammount() ]),
        ammounts1: this.formBuilder.array([ this.createammount1() ]),
        ammounts7: this.formBuilder.array([ this.createammount7() ]),
        ammounts8: this.formBuilder.array([ this.createammount8() ]),
        ammounts0: this.formBuilder.array([ this.createammount0() ]),

        ammounts10: this.formBuilder.array([ this.createammount10() ])
     });
     this.recetteform = this.formBuilder.group({
      journal:'',
      cptedt:'',
      ammounts2: this.formBuilder.array([ this.createammount2() ])
   });
   this.achatform = this.formBuilder.group({
    journal:'',
    cptedt:'',
    cptetva:'',
    ammounts3: this.formBuilder.array([ this.createammount3() ]),
    ammounts4: this.formBuilder.array([ this.createammount4() ]),
    ammounts9: this.formBuilder.array([ this.createammount9() ]),
    ammounts11: this.formBuilder.array([ this.createammount11() ])


 });
 this.releveform = this.formBuilder.group({
  ammounts5: this.formBuilder.array([ this.createammount5() ]),
  ammounts6: this.formBuilder.array([ this.createammount6() ]),
});
this.tvaafficheform = this.formBuilder.group({
  ammounts12: this.formBuilder.array([ this.createammount12() ])
});
this.copform = this.formBuilder.group({
  ammounts13: this.formBuilder.array([ this.createammount13() ])
});
    }
  ngOnInit() {
    this.loading = true;
    this.route.params.subscribe(
      (params) => {
        this.userservice.getUserById(params.id).then(
          (user: User) => {
            this.user = user; 
            this.user.exportateur?this.tot=true:this.tot=false
          this.user.salaire?this.sal=true:this.sal=false
          this.user.matfiscale&&this.user.activite||this.user.activite != 'Syndic des copropriétaires'&&this.user.activite?this.mat=true:this.mat=false
          console.log(this.mat)
          this.copform = new FormGroup({
            ammounts13: new FormArray(user.coproprietaires.map(item => 
              {
              const group = this.createammount13();
              //@ts-ignore
              group.patchValue(item);
              return group;
            }))
          });
          if(this.user.nature=="Personne Morale")
          {
            this.natureactivites=this.natureactivitesmorale
            /*this.activites=this.activitessociete
            this.sousactivites=this.sousactivitesservice*/
          }
          else if(this.user.nature=="Personne Physique")
          {
            this.natureactivites=this.natureactivitesphysique
            /*this.activites=this.activitessociete
            this.sousactivites=this.sousactivitesservice*/
          }
          if(this.user.natureactivite=='association et syndic')
          {
      this.activites=this.activitesassociations
      this.fiscaltvaassoblis=this.fiscaltvaassoblisassociation
          }
          else if (this.user.natureactivite=='Profession Libérale')
          {
            this.activites=this.activitesliberales
            this.fiscaltvaassoblis=this.fiscaltvaassoblisliberale
      
          }
          else if (this.user.natureactivite=='société')
          {
            this.activites=this.activitessociete
            this.natsociete=true
          }
          if (this.user.activite == 'Avocat') {
            this.sousactivites = this.sousactivitesavocat;
          } else if (this.user.activite == 'Médecin') {
            this.sousactivites = this.sousactivitesmedecin;
          } else if (this.user.activite == 'Ventes en gros') {
            this.sousactivites = this.sousactivitesventeengros;
          } else if (this.user.activite == 'Ventes en détail') {
            this.sousactivites = this.sousactivitesventeendetail;
          } else if (this.user.activite == 'Consommation sur place') {
            this.sousactivites = this.sousactivitesconsommationsurplace;
          } else if (this.user.activite == 'Batiment et travaux annexes') {
            this.sousactivites = this.sousactivitesbatimentettravauxannexe;
          } else if (this.user.activite == 'Réparation automobiles') {
            this.sousactivites = this.sousactivitesreparationautomobile;
          } else if (this.user.activite == 'Menuiserie') {
            this.sousactivites = this.sousactivitesmenuiserie;
          } else if (this.user.activite == 'Commerciale') {
            this.sousactivites = this.sousactivitescommerciale;
          } else if (this.user.activite == 'Industrielle') {
            this.sousactivites = this.sousactivitesindustrielle;
          } else if (this.user.activite == 'Service') {
            this.sousactivites = this.sousactivitesservice;
          } 
          else if (this.user.activite == 'services liées aux manifestations et cérémonies') {
            this.sousactivites = this.sousactivitesmanifestation;
          } 
          else {
            this.sousactivites = [];  // Default to empty if no matching activite found
          }
          if(this.user.sousactivite!="Médecin spécialiste"&&this.user.sousactivite!="Transport pour le compte d'autrui")
          {
            this.specialites=[]
          }
            
this.deccomptabilitesSub = this.deccompt.deccomptabilites$.subscribe(
  (deccomptabilites) => {
    this.deccomptabilites = deccomptabilites;
    this.showparamcomptable=true
    //@ts-ignore
this.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.clients.push({cl:item.client})))))
this.user.activite!='Syndic des copropriétaires'?this.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.clients.push({cl:e.client})))):''
this.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.copnatures.push(e.nature))))
this.deccomptabilites.forEach(element => element.autre0.forEach(e => (this.copnaturesnon.push(e.nature))))

this.deccomptabilites.forEach(element => element.autre1.forEach(e => (this.copobjets.push(e.objet))))
//@ts-ignore
this.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (this.fournisseurs.push({four:item.fournisseur})))))
this.deccomptabilites.forEach(element => element.autre3.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}),this.reglements.push({reg:e.reglement}))))
this.deccomptabilites.forEach(element => element.autre9.forEach(e => (this.fournisseurs.push({four:e.fournisseur}),this.natures.push({nat:e.natureachat}))))

this.deccomptabilites.forEach(element => element.autre8.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}),this.natures.push({nat:e.natureachat}),this.reglements.push({reg:e.reglement}))))
this.deccomptabilites.forEach(element => element.autre11.forEach(e => (this.beneficiaires.push({four:e.beneficiaire}),this.natures.push({nat:e.natureachat}),this.reglements.push({reg:e.reglement}))))

this.deccomptabilites.forEach(element => element.autre4.forEach(e => (this.numeros.push({num:e.numerocompte}))))
this.deccomptabilites.forEach(element => element.autre4.forEach(e => (this.banques.push({bq:e.banque,num:e.numerocompte}))))
//@ts-ignore
this.deccomptabilites.forEach(element => element.autre4.forEach(e => e.mouvements.forEach(item => (item.contientauxiliaire!=true?(this.objetsdebit.push(item.objetdebit),this.objetscredit.push(item.objetcredit)):''))))    
this.loading = false;
this.settedbeneficiaires=this.beneficiaires.filter((obj, index) => {
  return index === this.beneficiaires.findIndex(o => obj.four === o.four);
});
this.settedbeneficiaires=this.settedbeneficiaires.sort()
this.settedcopnatures= this.copnatures.filter((obj, index) => {
  return index === this.copnatures.findIndex(o => obj === o);
});
this.settedcopnaturesnon= this.copnaturesnon.filter((obj, index) => {
  return index === this.copnaturesnon.findIndex(o => obj === o);
});
this.settedcopobjets= this.copobjets.filter((obj, index) => {
  return index === this.copobjets.findIndex(o => obj === o);
});
this.settedreglements= this.reglements.filter((obj, index) => {
  return index === this.reglements.findIndex(o => obj.reg === o.reg);
});
    this.settedclients= this.clients.filter((obj, index) => {
      return index === this.clients.findIndex(o => obj.cl === o.cl);
    });
    this.settednatures= this.natures.filter((obj, index) => {
      return index === this.natures.findIndex(o => obj.nat === o.nat);
    });
    this.settedfournisseurs=this.fournisseurs.filter((obj, index) => {
      return index === this.fournisseurs.findIndex(o => obj.four === o.four);
    });
    this.settednumeros=this.numeros.filter((obj, index) => {
      return index === this.numeros.findIndex(o => obj.num === o.num);
    });
   this.banques=this.banques.filter((obj, index) => {
      return index === this.banques.findIndex(o => obj.num === o.num);
    });
    this.settedobjetsdebit=this.objetsdebit.filter((obj, index) => {
      return index === this.objetsdebit.findIndex(o => obj === o);
    });
    this.settedobjetscredit=this.objetscredit.filter((obj, index) => {
      return index === this.objetscredit.findIndex(o => obj === o);
    });
    this.settedobjets=this.settedobjets.concat(this.settedobjetscredit,this.settedobjetsdebit)
  },
  (error) => {
    this.loading = false;
    this.errormsg=error.message;
  }
);
this.decfiscmensSub = this.decfiscmens.decfiscmenss$.subscribe(
  (decfiscmenss) => {
    this.decfiscmenss = decfiscmenss;
  },
  (error) => {
    this.loading = false;
    this.errormsg=error.message;
  }
);
this.deccompt.getdeccomptabilite(user._id)
this.decfiscmens.getdecfiscmens(user._id)

this.tvaafficheform = this.formBuilder.group({ 
  //@ts-ignore
  ammounts12: new FormArray(user.tvaaffiche.length>0?user.tvaaffiche.map((item,index) => {
    const group = this.createammount12();
    //@ts-ignore
    group.patchValue(
      {
        tauxtva:item.tauxtva,
        affiche:item.affiche=='oui'?true:false,
      }
    );
    return group;
  }):
  this.taux.map((element,index) => {
   const group = this.createammount12();
   //@ts-ignore
   group.patchValue(
     {
       tauxtva:element,
       affiche:''
     }
   );
   return group;
 }))
 })
 user.tvaaffiche.length>0?this.taux.map((el,index) => {
  let ammounts12 = this.tvaafficheform.get('ammounts12') as FormArray;
                //@ts-ignore
  if(user.tvaaffiche.find(element=>element.tauxtva==el)==undefined)
  {
    const group = this.createammount12();
    //@ts-ignore
    group.patchValue(
      {
        tauxtva:el,
        affiche:''
      }
    );
ammounts12.push(group)            
}
}
):[];
console.log(this.user.matriculefiscale)
this.userForm = this.formBuilder.group({  
    
  role: [this.user.role],
            tarif: [this.user.prixspecialminute],
            droitcompta: [this.user.droitcompta],
            droitsaisie: [this.user.droitsaisie],
            rolesuperviseur: [this.user.rolesuperviseur],
            firstname: [this.user.firstname],
            lastname: [this.user.lastname],
            confirmemail: [null,],
            mobile: [this.user.mobile,],
            confirmmobile: [null,],
            usertype: [this.user.usertype,],
            nature: [this.user.nature,],
            email: [this.user.email, Validators.email],
            fonction: [this.user.fonction],
            password: [this.user.password],
            secteur: [this.user.secteur],
            civilite: [this.user.civilite],
            raisonsociale: [this.user.raisonsociale],
            nomsociete: [this.user.nomsociete],
            clientcode: [{value:this.user.clientcode,disabled:true}],
  export: [this.user.exportateur,],
  salaire: [this.user.salaire,],
  matfiscale: [this.user.matfiscale,],
  image: [this.user.ficheUrl||'',],
  image2: [this.user.ficheUrl2||'',],
  image3: [this.user.ficheUrl3||'',],
  image4: [this.user.ficheUrl4||'',],
  image5: [this.user.ficheUrl5||'',],
  activitynature: [this.user.natureactivite],
  selectactivitynature: [null,],
  activity: [this.user.activite,],
  selectactivity:[null,],
  underactivity: [this.user.sousactivite,],
  selectunderactivity:[null,],
  specialite: [this.user.specialite,],
  sousspecialite: [this.user.sousspecialite,],
  fiscalimpot: [this.user.regimefiscalimpot,],
  selectfiscalimpot:[null,],
  fiscaltvaassobli: ["Assujeti Obligatoire"],
  fiscalmat:this.user.matriculefiscale?[this.user.matriculefiscale.split(' ')[0],[Validators.pattern(this.fiscalmatPattern),Validators.maxLength(7),Validators.required]]
  :['',[Validators.pattern(this.fiscalmatPattern),Validators.maxLength(7)]],
  fiscalmatletter:this.user.matriculefiscale? [this.user.matriculefiscale.split(' ')[1].split('/')[0],[Validators.pattern(this.fiscalmatletterPattern),Validators.maxLength(1),Validators.required]]
  :['',[Validators.pattern(this.fiscalmatletterPattern),Validators.maxLength(1)]],
  fiscalmatinchanged:this.user.matriculefiscale?[this.user.matriculefiscale.split(' ')[1].split('/')[1],[Validators.pattern(this.fiscalmatinchangedletterPattern),Validators.maxLength(1),Validators.required]]:['',[Validators.pattern(this.fiscalmatinchangedletterPattern),Validators.maxLength(1)]],
  fiscalmatinchanged2:this.user.matriculefiscale?[this.user.matriculefiscale.split(' ')[1].split('/')[2],[Validators.pattern(this.fiscalmatsecondinchangedletterPattern),Validators.maxLength(1),Validators.required]]:['',[Validators.pattern(this.fiscalmatsecondinchangedletterPattern),Validators.maxLength(1)]],
  fiscalmatnumbers:this.user.matriculefiscale? [this.user.matriculefiscale.split('/')[3],[Validators.pattern(this.fiscalmatnumbersPattern),Validators.maxLength(3),Validators.required]]
  :['',[Validators.pattern(this.fiscalmatnumbersPattern),Validators.maxLength(3)]],
  adresseactivite: [this.user.adresseactivite,],
  dateeffet: [this.user.dateeffet],
  codepostal:[this.user.codepostal,[Validators.maxLength(4)]],
  regimehoraire: [this.user.regimehoraire,],
  tauxaccidenttravail: [this.user.tauxaccidenttravail,],
  matriculecnss: [this.user.matriculecnss,],
  tauxchargepatronale: [this.user.tauxchargepatronale,],
  countingdays: [this.user.countingdays,],
},
) 
            this.loading = false;
          
          }
        );
      }
    );                                                   
    this.usersSub = this.userservice.users$.subscribe(
      (users) => {
        this.users = users;
        this.loading = false;
        
      },
      (error) => {
        this.loading = false;
        console.log(error);
        this.errormsg=error.message;
      }
    );

    this.userservice.getAll();
    /*this.createrelevemanuelForm();

    // Direct patching does not patch nested arrays
    this.patchValuesDirectly();

    //Correct way
    this.patchValueIteratively();*/
  }
  get f() { return this.userForm.controls; } 
  /*createrelevemanuelForm() {
    this.releveform = this.formBuilder.group({
      employees: this.formBuilder.array([this.newEmployee()]),
    });
  }
  patchValuesDirectly() {
    this.deccompt.autre4.map(item => { 
      console.log(item)   
      //@ts-ignore
      this.relevemanuelform.patchValue(
        {
          type:item.type,
          banque:item.banque,
          numerocompte:item.numerocompte,
          debit:item.soldedebit,
          credit:item.soldecredit,
          skills:item.mouvements
        }
      )});
    console.log(this.relevemanuelform.value);
  }
  patchValueIteratively() {
    let autre4 = this.deccomptabilite.autre4;
    (this.relevemanuelform.get('employees') as FormArray).clear(); //Removing initial item b/c you are creating form array with 1 initial value
    autre4.forEach((car) => {
      const vehicleForm = this.newEmployee();
      vehicleForm.patchValue({
                        
        type:car.type,
        banque:car.banque,
        numerocompte:car.numerocompte,
        debit:car.soldedebit,
        credit:car.soldecredit,
        skills:car.mouvements
      });
      //Create Cars FormArray individually & patch the value
      (vehicleForm.get('skills') as FormArray).clear(); //Initially blank
      car.mouvements.forEach((item) => {
        const carForm = this.newSkill();
        carForm.patchValue(item);
        
        (vehicleForm.get('skills') as FormArray).push(carForm);
        this.totaldebitbis = +(vehicleForm.get('skills').value).reduce((acc,curr)=>{
          acc += +(curr.debit || 0);
          return acc;
        },0);
        this.totalcreditbis = +(vehicleForm.get('skills').value).reduce((acc,curr)=>{
          acc += +(curr.credit || 0);
          return acc;
        },0);    });
      (this.relevemanuelform.get('employees') as FormArray).push(vehicleForm);
    });
    console.log(this.relevemanuelform.value);
  }*/
  onTabClick(event) {
   
  }
  onTabChange(event: any) {
    this.selectedTabIndex = event.index;
    this.selectedTabIndex==1?this.myFunction2():(this.countdown=0,this.intervalSub.unsubscribe())
  }
  onTabChangesections(event: any) {
    this.selectedTabIndexsection = event.index;
  }
  onTabChangesdonnessignaletique(event: any) {
    this.selectedTabIndexsignaletique = event.index;
  }
  verifyfutur(e,i)
  {
    /*let ammounts = this.editionnoteform.get('ammounts') as FormArray;
    let ammountssliced=ammounts.getRawValue().slice(0,-1)
    if(ammounts.controls[i].get('type').value=='recette journaliere')
    {
      ammounts.controls[i].get('cptedt').disable()
      ammounts.controls[i].get('cptedtdebit').disable()
      ammounts.controls[i].get('fournisseur').disable()
      ammounts.controls[i].get('natureachat').disable()
      ammounts.controls[i].get('client').disable()
    }
    if(ammounts.controls[i].get('type').value=='edition note honoraire')
    {
      ammounts.controls[i].get('fournisseur').disable()
      ammounts.controls[i].get('natureachat').disable()
    }
    if(ammounts.controls[i].get('type').value=='achat')
    {
      ammounts.controls[i].get('client').disable()
      ammounts.controls[i].get('taux').disable()
    }
    this.userservice.getUserById(this.user._id).then(
      (data:User) => { 
        if(data.paramcomptable)
        {
            if(ammounts.length>1&&ammounts.controls[i].get('type').value=='recette journaliere')
            {
              console.log(ammounts.controls[i].get('type').value)
              console.log(ammounts.controls[i].get('taux').value)
//@ts-ignore
if(data.paramcomptable.find((element =>element.type === ammounts.controls[i].get('type').value&&element.taux === ammounts.controls[i].get('taux').value))
//@ts-ignore
||ammountssliced.find((element =>element.type === ammounts.controls[i].get('type').value&&element.taux === ammounts.controls[i].get('taux').value)))
return (
  Swal.fire({
  title: 'vous ne pouvez pas choisir un type déjà parametré',
  icon: 'error',
  confirmButtonColor: '#3085d6',
}).then((result) => {this.removeammount(i)
}).catch(() => {
  Swal.fire('opération non aboutie!')
})) 
            } 
            if(ammounts.length>1&&ammounts.controls[i].get('type').value=='edition note honoraire')
            {
              console.log(ammounts.value)
//@ts-ignore
if(data.paramcomptable.find((element =>element.type === ammounts.controls[i].get('type').value &&element.client === ammounts.controls[i].get('client').value&&element.taux === ammounts.controls[i].get('taux').value))
||ammountssliced.find((element =>element.type === ammounts.controls[i].get('type').value&&element.client === ammounts.controls[i].get('client').value&&element.taux === ammounts.controls[i].get('taux').value)))
return (
  Swal.fire({
  title: 'vous ne pouvez pas choisir un type déjà parametré',
  icon: 'error',
  confirmButtonColor: '#3085d6',
}).then((result) => {this.removeammount(i)
}).catch(() => {
  Swal.fire('opération non aboutie!')
})) 
            } 
            if(ammounts.length>1&&ammounts.controls[i].get('type').value=='achat')
            {
              console.log(ammounts.value)
//@ts-ignore
if(data.paramcomptable.find((element =>element.type === ammounts.controls[i].get('type').value&&element.fournisseur === ammounts.controls[i].get('fournisseur').value&&element.nature === ammounts.controls[i].get('natureachat').value&&element.taux === ammounts.controls[i].get('taux').value))
||ammountssliced.find((element =>element.type === ammounts.controls[i].get('type').value&&element.fournisseur === ammounts.controls[i].get('fournisseur').value&&element.nature === ammounts.controls[i].get('natureachat').value&&element.taux === ammounts.controls[i].get('taux').value)))
return (
  Swal.fire({
  title: 'vous ne pouvez pas choisir un type déjà parametré',
  icon: 'error',
  confirmButtonColor: '#3085d6',
}).then((result) => {this.removeammount(i)
}).catch(() => {
  Swal.fire('opération non aboutie!')
})) 
            } 
        }    
      }  
    )*/
  }
  get ammountControls() {
    return this.editionnoteform.get('ammounts')['controls'];
  }
  get ammountControls1() {
    return this.editionnoteform.get('ammounts1')['controls'];
  }
  get ammountControls10() {
    return this.editionnoteform.get('ammounts10')['controls'];
  }
  get ammountControls2() {
    return this.recetteform.get('ammounts2')['controls'];
  }
  get ammountControls3() {
    return this.achatform.get('ammounts3')['controls'];
  }
  get ammountControls11() {
    return this.achatform.get('ammounts11')['controls'];
  }
  get ammountControls4() {
    return this.achatform.get('ammounts4')['controls'];
  }
  get ammountControls5() {
    return this.releveform.get('ammounts5')['controls'];
  }
  get ammountControls6() {
    return this.releveform.get('ammounts6')['controls'];
  }
  get ammountControls7() {
    return this.editionnoteform.get('ammounts7')['controls'];
  }
  get ammountControls8() {
    return this.editionnoteform.get('ammounts8')['controls'];
  }
  get ammountControls0() {
    return this.editionnoteform.get('ammounts0')['controls'];
  }
  get ammountControls9() {
    return this.achatform.get('ammounts9')['controls'];
  }
  get ammountControls12() {
    return this.tvaafficheform.get('ammounts12')['controls'];
  }
  get ammountControls13() {
    return this.copform.get('ammounts13')['controls'];
  }
  createammount(): FormGroup {
    return this.formBuilder.group({
      taux: '', 
      cpteht: '',
      cptehtfodec: '',
      cptehttpe: '',
      cptetva: '',
    });
  }
  createammount1(): FormGroup {
    return this.formBuilder.group({
      client:'',
      cptettc: '',
    });
  }
  createammount10(): FormGroup {
    return this.formBuilder.group({
      coproprietaire:'',
      cpte: '',
    });
  }
  createammount2(): FormGroup {
    return this.formBuilder.group({
      taux: '',
      cpteht: '',
      cptetva: '', 
      cptettc: '',     
    });
  }
  createammount3(): FormGroup {
    return this.formBuilder.group({ 
      fournisseur:'',
      cptettc: '',
    });
  }
  createammount11(): FormGroup {
    return this.formBuilder.group({ 
      beneficiaire:'',
      cpte: '',
    });
  }
  createammount4(): FormGroup {
    return this.formBuilder.group({ 
      natureachat:'',
      cpteht: '',
      cptehtdevise: '',
    });
  }
  createammount5(): FormGroup {
    return this.formBuilder.group({
      banque:'',
      numerocompte: '',
      cpte: '',
      journalmanuel: '',
    });
  }
  createammount6(): FormGroup {
    return this.formBuilder.group({
      objet:'',
      cpte: '',
    });
  }
  createammount7(): FormGroup {
    return this.formBuilder.group({
      objet:'',
      cpte: '',
    });
  }
  createammount8(): FormGroup {
    return this.formBuilder.group({
      nature:'',
      cpte: '',
    });
  }
  createammount0(): FormGroup {
    return this.formBuilder.group({
      nature:'',
      cpte: '',
    });
  }
  createammount9(): FormGroup {
    return this.formBuilder.group({
      reglement:'',
      cpte: '',
      journal: '',

    });
  }
  createammount12(): FormGroup {
    return this.formBuilder.group({
      tauxtva:'',
      affiche: '',
    });
  }
  createammount13() 
  : FormGroup {
    
    return  this.formBuilder.group({
      numero:'',
      coproprietaire: '',
      donneescomptable: [],
      
    });
  }
  addammount13(i: number): void {
    this.ammounts13 = this.copform.get('ammounts13') as FormArray;
    this.ammounts13.insert(i+1,this.createammount13());
  }
  removeammount13(i: number) {
    this.ammounts13 = this.copform.get('ammounts13') as FormArray;
    this.ammounts13.removeAt(i);
  }
  addammount(): void {
    this.ammounts = this.editionnoteform.get('ammounts') as FormArray;
    this.ammounts.push(this.createammount());
  }
  addammount1(): void {
    this.ammounts1 = this.editionnoteform.get('ammounts1') as FormArray;
    this.ammounts1.push(this.createammount1());
  }
  addammount10(): void {
    this.ammounts10 = this.editionnoteform.get('ammounts10') as FormArray;
    this.ammounts10.push(this.createammount10());
  }
  addammount2(): void {
    this.ammounts2 = this.recetteform.get('ammounts2') as FormArray;
    this.ammounts2.push(this.createammount2());
  }
  addammount3(): void {
    this.ammounts3 = this.achatform.get('ammounts3') as FormArray;
    this.ammounts3.push(this.createammount3());
  }
  addammount11(): void {
    this.ammounts11 = this.achatform.get('ammounts11') as FormArray;
    this.ammounts11.push(this.createammount11());
  }
  addammount4(): void {
    this.ammounts4 = this.achatform.get('ammounts4') as FormArray;
    this.ammounts4.push(this.createammount4());
  }
  addammount5(): void {
    this.ammounts5 = this.releveform.get('ammounts5') as FormArray;
    this.ammounts5.push(this.createammount5());
  }
  addammount6(): void {
    this.ammounts6 = this.releveform.get('ammounts6') as FormArray;
    this.ammounts6.push(this.createammount6());
  }
  addammount7(): void {
    this.ammounts7 = this.editionnoteform.get('ammounts7') as FormArray;
    this.ammounts7.push(this.createammount7());
  }
  addammount8(): void {
    this.ammounts8 = this.editionnoteform.get('ammounts8') as FormArray;
    this.ammounts8.push(this.createammount8());
  }
  addammount0(): void {
    this.ammounts0 = this.editionnoteform.get('ammounts0') as FormArray;
    this.ammounts0.push(this.createammount0());
  }
  addammount9(): void {
    this.ammounts9 = this.achatform.get('ammounts9') as FormArray;
    this.ammounts9.push(this.createammount9());
  }
  addammount12(): void {
    this.ammounts12 = this.tvaafficheform.get('ammounts12') as FormArray;
    this.ammounts12.push(this.createammount12());
  }
  removeammount(i: number) {
    this.ammounts = this.editionnoteform.get('ammounts') as FormArray;
    this.ammounts.removeAt(i);
  }
  removeammount1(i: number) {
    this.ammounts1 = this.editionnoteform.get('ammounts1') as FormArray;
    this.ammounts1.removeAt(i);
  }
  removeammount10(i: number) {
    this.ammounts10 = this.editionnoteform.get('ammounts10') as FormArray;
    this.ammounts10.removeAt(i);
  }
  removeammount2(i: number) {
    this.ammounts2 = this.recetteform.get('ammounts2') as FormArray;
    this.ammounts2.removeAt(i);
  }
  removeammount3(i: number) {
    this.ammounts3 = this.achatform.get('ammounts3') as FormArray;
    this.ammounts3.removeAt(i);
  }
  removeammount11(i: number) {
    this.ammounts11 = this.achatform.get('ammounts11') as FormArray;
    this.ammounts11.removeAt(i);
  }
  removeammount4(i: number) {
    this.ammounts4 = this.achatform.get('ammounts4') as FormArray;
    this.ammounts4.removeAt(i);
  }
  removeammount5(i: number) {
    this.ammounts5 = this.releveform.get('ammounts5') as FormArray;
    this.ammounts5.removeAt(i);
  }
  removeammount6(i: number) {
    this.ammounts6 = this.releveform.get('ammounts6') as FormArray;
    this.ammounts6.removeAt(i);
  }
  removeammount7(i: number) {
    this.ammounts7 = this.editionnoteform.get('ammounts7') as FormArray;
    this.ammounts7.removeAt(i);
  }
  removeammount8(i: number) {
    this.ammounts8 = this.editionnoteform.get('ammounts8') as FormArray;
    this.ammounts8.removeAt(i);
  }
  removeammount0(i: number) {
    this.ammounts0 = this.editionnoteform.get('ammounts0') as FormArray;
    this.ammounts0.removeAt(i);
  }
  removeammount9(i: number) {
    this.ammounts9 = this.achatform.get('ammounts9') as FormArray;
    this.ammounts9.removeAt(i);
  }
  removeammount12(i: number) {
    this.ammounts12 = this.tvaafficheform.get('ammounts12') as FormArray;
    this.ammounts12.removeAt(i);
  }
  saveparam(event: any) {
  event.target.disabled = true; 
  this.loading = true;
  const user = new User();
  user.userId = this.user._id;
  if(this.option1Value==true)
  {
this.dosspasencorevalide=[]
let dossnonaffecte1=[]
let dossnonaffecte2=[]
let dossnonaffecte3=[]
dossnonaffecte1=((this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte
          //@ts-ignore                                                            

&&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='déposé par le client':'')))

dossnonaffecte2=(this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte  
          //@ts-ignore                                                            

&&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut!='clôturé':''))

dossnonaffecte3=((this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte
          //@ts-ignore                                                            

&&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient!='déposé par le client':''
||!deccomptabilite.affecte&&deccomptabilite.statutcollab.length==0)))

this.dosspasencorevalide=this.dosspasencorevalide.concat(dossnonaffecte1,dossnonaffecte2,dossnonaffecte3)
const sort = new Sort();
this.sorteddosspasencorevalide=this.dosspasencorevalide.sort(sort.startSort('created','asc',''));
for (let i = 0; i < this.sorteddosspasencorevalide.length ; i++) 
{
  var checkbox:any = document.getElementById('check'+`${i}`);  
  if (checkbox.checked == true)
  {
    this.commun.getcurrenttime().then(
      (data:any) => {
        if(this.sorteddosspasencorevalide[i].nature=='Déclaration Mensuelle')
        {
          this.sorteddosspasencorevalide[i].statutparam.push
          //@ts-ignore
          ({
            statutparametrage:'paramêtrage effectué',
            motif:'',
            datefin:data,
             duree:Math.floor((Date.now()-this.firsttimer) / 1000), 
          })                      
          this.decfiscmens.completedecfiscmensreqById(this.sorteddosspasencorevalide[i]._id,this.sorteddosspasencorevalide[i]).then(
            (data:any) => {
              console.log(this.sorteddosspasencorevalide[i])
            },
            (error) => {
              this.loading = false; 
        }
        );
        }
        if(this.sorteddosspasencorevalide[i].nature=='comptabilite')
        {
          this.sorteddosspasencorevalide[i].statutparam.push
          //@ts-ignore
          ({
            statutparametrage:'paramêtrage effectué',
            motif:'',
            datefin:data,
             duree:Math.floor((Date.now()-this.firsttimer) / 1000), 
          })                      
          this.deccompt.completedeccomptabilitereqById(this.sorteddosspasencorevalide[i]._id,this.sorteddosspasencorevalide[i]).then(
            (data:any) => {
              console.log(this.sorteddosspasencorevalide[i])
            },
            (error) => {
              this.loading = false; 
        }
        );
        }
      }
    )
  
  } 
}

  }
 user.paramcomptable=[]
  user.paramcomptable.push(
    //@ts-ignore
    {
      journalnote:this.editionnoteform.get('journal').value,
      journalnotenon:this.editionnoteform.get('journalnon').value,
      journalcopnote:this.editionnoteform.get('journalcop').value,

      totalnote:this.editionnoteform.get('total').value,
      cptetimbrefiscalnote:this.editionnoteform.get('cptedt').value,
      comptestvanote:this.editionnoteform.get('ammounts').value,
      comptesttcnote:this.editionnoteform.get('ammounts1').value,
      comptescoproprietaire:this.editionnoteform.get('ammounts10').value,

      comptesobjetnote:this.editionnoteform.get('ammounts7').value,
      comptesnaturenote:this.editionnoteform.get('ammounts8').value,
      comptesnaturenotenon:this.editionnoteform.get('ammounts0').value,

      journalrecette:this.recetteform.get('journal').value,
      cptetimbrefiscalrecette:this.recetteform.get('cptedt').value,
      comptestvarecette:this.recetteform.get('ammounts2').value,
      journalachat:this.achatform.get('journal').value,
      cptetimbrefiscalachat:this.achatform.get('cptedt').value,
      comptetvaachat:this.achatform.get('cptetva').value,
      comptesttcachat:this.achatform.get('ammounts3').value,
      comptesbeneficiaires:this.achatform.get('ammounts11').value,

      compteshtachat:this.achatform.get('ammounts4').value,
      comptesreglementachat:this.achatform.get('ammounts9').value,

      comptesmanuelcompte:this.releveform.get('ammounts5').value,
      comptesmanuelobjets:this.releveform.get('ammounts6').value,
    }
  )
  console.log(user.paramcomptable)
 this.userservice.modifyUserById(user.userId,user).then(
    (data:any) => {
          this.loading = false;
          //this.reloadPage()
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'paramêtrage ajouté avec succès!',
            showConfirmButton: false,
            timer: 6000 
          });
        },
        (error) => {
          this.loading = false;   
        }
      )
  }
  onSubmit() {
    let ammounts12 = this.tvaafficheform.get('ammounts12') as FormArray;
    this.loading = true;
    const user = new User();
    user.tvaaffiche=[]
    user.userId = this.user._id;
    user.role = this.userForm.get('role').value;
    user.prixspecialminute = this.userForm.get('tarif').value;
    user.droitcompta = this.userForm.get('droitcompta').value;
    user.droitsaisie = this.userForm.get('droitsaisie').value;
    user.rolesuperviseur = this.userForm.get('rolesuperviseur').value;
    user.email = this.userForm.get('email').value;
    user.password =this.userForm.get('password').value;
    user.firstname = this.userForm.get('firstname').value;
    user.mobile = this.userForm.get('mobile').value;
    user.usertype =this.userForm.get('usertype').value;
    user.nature =this.userForm.get('nature').value;
    user.lastname = this.userForm.get('lastname').value;
    user.fonction = this.userForm.get('fonction').value;
    user.secteur = this.userForm.get('secteur').value;
    user.civilite = this.userForm.get('civilite').value;
    user.raisonsociale = this.userForm.get('raisonsociale').value;
    user.nomsociete = this.userForm.get('nomsociete').value;
    user.clientcode = this.userForm.get('clientcode').value;
    user.matriculecnss = this.userForm.get('matriculecnss').value;
    user.tauxchargepatronale = this.userForm.get('tauxchargepatronale').value;
    user.tauxaccidenttravail = this.userForm.get('tauxaccidenttravail').value;
    user.regimehoraire = this.userForm.get('regimehoraire').value;
    user.exportateur = this.userForm.get('export').value;
    user.salaire = this.userForm.get('salaire').value;
    user.matfiscale = this.userForm.get('matfiscale').value;
    user.ficheUrl=this.user.ficheUrl
    user.ficheUrl2=this.user.ficheUrl2
    user.ficheUrl3=this.user.ficheUrl3
    user.ficheUrl4=this.user.ficheUrl4
    user.ficheUrl5=this.user.ficheUrl5
    if (this.userForm.get('activitynature').value=="Autre") { user.natureactivite = this.userForm.get('activitynature').value+'/'+this.userForm.get('selectactivitynature').value}
    else  {user.natureactivite = this.userForm.get('activitynature').value};
    if (this.userForm.get('activity').value=="Autre") {user.activite = this.userForm.get('activity').value+'/'+this.userForm.get('selectactivity').value}
    else {user.activite =this.userForm.get('activity').value};
    if (this.userForm.get('underactivity').value=="Autre") {user.sousactivite = this.userForm.get('underactivity').value+'/'+this.userForm.get('selectunderactivity').value}
    else {user.sousactivite =this.userForm.get('underactivity').value};
    if (this.userForm.get('fiscalimpot').value=="Autre") 
    { user.regimefiscalimpot = this.userForm.get('fiscalimpot').value+'/'+this.userForm.get('selectfiscalimpot').value}
    else {user.regimefiscalimpot = this.userForm.get('fiscalimpot').value};
    user.matriculefiscale = this.mat?this.userForm.get('fiscalmat').value+' '+this.userForm.get('fiscalmatletter').value+'/'+this.userForm.get('fiscalmatinchanged').value+'/'
    +this.userForm.get('fiscalmatinchanged2').value+'/'+this.userForm.get('fiscalmatnumbers').value:'';
    user.regimefiscaltva = this.userForm.get('fiscaltvaassobli').value;
    user.codepostal = this.userForm.get('codepostal').value;
    user.regimefiscaltva = this.userForm.get('fiscaltvaassobli').value;
    user.specialite = this.userForm.get('specialite').value;
    user.sousspecialite = this.userForm.get('sousspecialite').value;
    user.adresseactivite = this.userForm.get('adresseactivite').value;
    user.dateeffet = this.userForm.get('dateeffet').value;
    user.countingdays = this.userForm.get('countingdays').value;
    user.emailverified=this.emailverified
    user.mobileverified=this.mobileverified
    ammounts12.value.forEach(element => {
      element.affiche?element.affiche='oui':element.affiche='non'
    });
    user.tvaaffiche=ammounts12.value
    user.coproprietaires=this.copform.get('ammounts13').value
    user.coproprietaires=user.coproprietaires.filter(item => (item.numero!='0'&&item.numero!=''&&item.numero!=null));
 this.userservice.modifyUserwithimageById(this.user._id, user, this.uploadFiles).then( 
      (data:any) => {
        this.userForm.reset();
        this.loading = false;
        this.router.navigate(['admin-board']);
      },
      (error) => {
        this.loading = false;    
      }
    );
  }
  async emailconfirmwithotp() {
    try {
      // Récupérer l'email de l'utilisateur depuis le formulaire
      const email = this.userForm.get('email').value;

      // Vérifier si un utilisateur existe avec l'email fourni
      const data: any = await this.userservice.getUserByemail(email);
      console.log(data);

      // Si l'utilisateur existe
      if (data.length > 0) {
        // Demander à l'utilisateur de confirmer l'envoi de l'OTP
        const { value: confirmSendOtp } = await Swal.fire({
          title: 'Utilisateur trouvé',
          text: 'Un utilisateur avec cet email existe déjà. Nous allons vous envoyé un OTP pour confirmer que vous êtes le propriétaire de cette adresse email',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui, envoyer l\'OTP',
          cancelButtonText: 'Non, annuler'
        });

        // Si l'utilisateur confirme l'envoi de l'OTP
        if (confirmSendOtp) {
          // Créer un OTP
          const res: any = await this.otp.createwithoutfile();

          // Envoyer l'OTP à l'admin
          const contactAdmin = new Contact();
          contactAdmin.email = 'macompta@macompta.com.tn';
          contactAdmin.emailenvoyea = 'f.benayed@macompta.com.tn';
          contactAdmin.description = `code de vérification : ${res.data.otp.value}`;
          contactAdmin.typeemail = "OTP";
          await this.cont.comunicatewithuser(contactAdmin);

          // Envoyer l'OTP à l'utilisateur
          const contactUser = new Contact();
          contactUser.email = 'macompta@macompta.com.tn';
          contactUser.emailenvoyea = email;
          contactUser.description = `code de vérification : ${res.data.otp.value}`;
          contactUser.typeemail = "OTP";
          await this.cont.comunicatewithuser(contactUser);

          // Demander à l'utilisateur de saisir l'OTP
          const { value: otpCode } = await Swal.fire({
            title: 'Confirmation d\'identité',
            html: 'Veuillez saisir le code envoyé par email, il va expirer après 1 minute',
            input: 'text',
            inputLabel: 'Code',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Vous devez renseigner une valeur!';
              }
            }
          });

          // Valider l'OTP
          try {
            await this.otp.validateotp(otpCode);
            this.emailverified = true;
            this.resendemailotp=false
            await Swal.fire({
              title: 'OTP valide',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          } catch {
            this.resendemailotp=true
            await Swal.fire({
              title: 'OTP erroné',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
            this.userForm.patchValue({ email: null });
          }
        }
else{
  this.resendemailotp=true
}
      }
    } catch (error) {
      console.error('Erreur lors de la confirmation de l\'email avec OTP:', error);
      this.loading = false;
    }
  }
  
  
  async mobileconfirmwithotp() {
    try {
      // Récupérer le mobile de l'utilisateur depuis le formulaire
      const mobile = this.userForm.get('mobile').value;

      // Vérifier si un utilisateur existe avec le mobile fourni
      const datauser: any = await this.userservice.getUserBymobile(mobile);
      console.log(datauser);

      // Si l'utilisateur existe
      if (datauser) {
        // Demander à l'utilisateur de confirmer l'envoi de l'OTP
        const { value: confirmSendOtp } = await Swal.fire({
          title: 'Utilisateur trouvé',
          text: 'Un utilisateur avec ce numéro de mobile existe déjà. Nous allons vous envoyé un OTP pour confirmer que vous êtes le propriétaire de cette adresse email',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui, envoyer l\'OTP',
          cancelButtonText: 'Non, annuler'
        });

        // Si l'utilisateur confirme l'envoi de l'OTP
        if (confirmSendOtp) 
        {
          // Créer un OTP
          const res: any = await this.otp.createwithoutfile();

          // Envoyer l'OTP à l'admin
          const useradmin = {
            _id: '63c6a638b550e8e10edff18c',
            mobile: '21698350575'
          };
          const messageadmin = `code de vérification : ${res.data.otp.value}`;
          await this.userservice.comunicatewithusersms(useradmin, messageadmin);

          // Envoyer l'OTP à l'utilisateur
          const user = datauser;
          const message = `code de vérification : ${res.data.otp.value}`;
          await this.userservice.comunicatewithusersms(user, message);

          // Demander à l'utilisateur de saisir l'OTP
          const { value: numero } = await Swal.fire({
            title: 'Confirmation d\'identité',
            html: 'Veuillez saisir le code envoyé par SMS, il va expirer après 1 minute',
            input: 'text',
            inputLabel: 'Code',
            inputValue: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'Vous devez renseigner une valeur!';
              }
            }
          });

          // Valider l'OTP
          try {
            await this.otp.validateotp(numero);
            this.mobileverified = true;
            this.resendmobileotp=false
            await Swal.fire({
              title: 'OTP valide',
              icon: 'success',
              confirmButtonColor: '#3085d6',
            });
          } catch {
            await Swal.fire({
              title: 'OTP erroné',
              icon: 'error',
              confirmButtonColor: '#3085d6',
            });
            this.resendmobileotp=true
            this.userForm.patchValue({ mobile: null });
          }
        }
else
{
  this.resendmobileotp=true
}
      } 
    } catch (error) {
      console.error('Erreur lors de la confirmation du mobile avec OTP:', error);
      this.loading = false;
    }
  }
  myFunction2() {
      this.intervalSub=interval(1000).subscribe(value => this.countdown=value), this.countdown=0
      this.firsttimer=Date.now()

      this.userservice.getUserById(this.user._id).then(
        (user:User)=>
        {
       
          console.log(this.user.paramcomptable)

          this.editionnoteform = this.formBuilder.group({ 
             //@ts-ignore
             total: [user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].totalnote:''],
            //@ts-ignore
            journal: [user.paramcomptable[0]?user.paramcomptable[0].journalnote:''],
             //@ts-ignore
             journalnon: [user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].journalnotenon:''],
             //@ts-ignore
             journalcop: [user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].journalcopnote:''],
            //@ts-ignore
            cptedt: [user.paramcomptable[0]?user.paramcomptable[0].cptetimbrefiscalnote:''], 
            //@ts-ignore
            ammounts: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptestvanote.map((item,index) => {
              const group = this.createammount();
              //@ts-ignore
              group.patchValue(item);
              return group;
            }):[]),
            //@ts-ignore
ammounts1: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptesttcnote.map((item,index) => {
  const group = this.createammount1();
  //@ts-ignore
  group.patchValue(item);
  return group;
            }):
            this.settedclients.map((item,index) => {
              const group = this.createammount1();
              //@ts-ignore
              group.patchValue(
                {
                  client:item.cl,
                  cptettc:''
                }
              );
              return group;
            })),
             //@ts-ignore
ammounts10: new FormArray(user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].comptescoproprietaire.map((item,index) => {
  const group = this.createammount10();
  //@ts-ignore
  group.patchValue(item);
  return group;
            }):
            this.user.coproprietaires.map((item,index) => {
              const group = this.createammount10();
              //@ts-ignore
              group.patchValue(
                {
                  coproprietaire:item.numero,
                  cpte:''
                }
              );
              return group;
            })),
              //@ts-ignore
            ammounts7: new FormArray(user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].comptesobjetnote.map((item,index) => {
               
              const group = this.createammount7();
              //@ts-ignore
              item.objet!=='frais syndic'?group.patchValue(item):'';
              return group;
                        }):
                        this.settedcopobjets.map((item,index) => {
                          const group =this.createammount7();
                          //@ts-ignore
                          item!=='frais syndic'?group.patchValue(
                            {
                              objet:item,
                              cpte:''
                            }
                          ):'';
                          return group;
                        }))  ,
                        //@ts-ignore
                      ammounts8: new FormArray(user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].comptesnaturenote.map((item,index) => {
                        const group = this.createammount8();
                        //@ts-ignore
                        group.patchValue(item);
                        return group;
                                  }):
                                  this.settedcopnatures.map((item,index) => {
                                    const group = this.createammount8();
                                    //@ts-ignore
                                    group.patchValue(
                                      {
                                        nature:item,
                                        cpte:''
                                      }
                                    );
                                    return group;
                                  })) ,
                        //@ts-ignore
                      ammounts8: new FormArray(user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].comptesnaturenote.map((item,index) => {
                        const group = this.createammount8();
                        //@ts-ignore
                        group.patchValue(item);
                        return group;
                                  }):
                                  this.settedcopnatures.map((item,index) => {
                                    const group = this.createammount8();
                                    //@ts-ignore
                                    group.patchValue(
                                      {
                                        nature:item,
                                        cpte:''
                                      }
                                    );
                                    return group;
                                  })) 
                                  ,
                                  //@ts-ignore
                                ammounts0: new FormArray(user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'&&user.paramcomptable[0].comptesnaturenotenon?user.paramcomptable[0].comptesnaturenotenon.map((item,index) => {
                                  const group = this.createammount0();
                                  //@ts-ignore
                                  group.patchValue(item);
                                  return group;
                                            }):
                                            this.settedcopnaturesnon.map((item,index) => {
                                              const group = this.createammount0();
                                              //@ts-ignore
                                              group.patchValue(
                                                {
                                                  nature:item,
                                                  cpte:''
                                                }
                                              );
                                              return group;
                                            }))      
          });
          user.paramcomptable[0]?this.settedclients.map((el,index) => {
            let ammounts1 = this.editionnoteform.get('ammounts1') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesttcnote.find(element=>element.client==el.cl)==undefined)
            {
              const group = this.createammount1();
              //@ts-ignore
              group.patchValue(
                {
                  client:el.cl,
                  cptettc:''
                }
              );
ammounts1.push(group)            
}
          }
          ):[];
          user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?this.user.coproprietaires.map((el,index) => {
            let ammounts10 = this.editionnoteform.get('ammounts10') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptescoproprietaire.find(element=>element.coproprietaire==el.numero)==undefined)
            {
              const group = this.createammount10();
              //@ts-ignore
              group.patchValue(
                {
                  coproprietaire:el.numero,
                  cpte:''
                }
              );
ammounts10.push(group)            
}
          }
          ):[];
          user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?  this.settedcopobjets.map((el,index) => {
            let ammounts7 = this.editionnoteform.get('ammounts7') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesobjetnote.find(element=>element.objet==el)==undefined&&el!=='frais syndic')
            {
              const group =  this.createammount7();
              //@ts-ignore
            group.patchValue(
                {
                  objet:el,
                  cpte:''
                }
              );
ammounts7.push(group)            
}
          }
          ):[];
          user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?  this.settedcopnatures.map((el,index) => {
            let ammounts8 = this.editionnoteform.get('ammounts8') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesnaturenote.find(element=>element.nature==el)==undefined)
            {
              const group = this.createammount8();
              //@ts-ignore
              group.patchValue(
                {
                  nature:el,
                  cpte:''
                }
              );
ammounts8.push(group)            
}
          }
          ):[];
                                    //@ts-ignore
          user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'&&user.paramcomptable[0].comptesnaturenotenon?  this.settedcopnaturesnon.map((el,index) => {
            let ammounts0 = this.editionnoteform.get('ammounts0') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesnaturenotenon.find(element=>element.nature==el)==undefined)
            {
              const group = this.createammount0();
              //@ts-ignore
              group.patchValue(
                {
                  nature:el,
                  cpte:''
                }
              );
ammounts0.push(group)            
}
          }
          ):[];
          this.releveform = this.formBuilder.group({ 
            //@ts-ignore
            ammounts5: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.map((item,index) => {
              const group = this.createammount5();
              //@ts-ignore
              group.patchValue({
                banque:this.banques[index]?this.banques[index].bq:'',
                numerocompte:item.numerocompte,
                cpte:item.cpte,
                journalmanuel:item.journalmanuel,
              });
              return group;
            }):
            this.settednumeros.map((item,index) => {
              const group = this.createammount5();
              //@ts-ignore
              group.patchValue(
                {
                  banque:this.banques[index]?this.banques[index].bq:'',
                  numerocompte:item.num,
                  cpte:'',
                  journalmanuel:'',

                }
              );
              return group;
            })
            ),
                          //@ts-ignore
ammounts6: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelobjets.map((item,index) => {
                            const group = this.createammount6();
                            //@ts-ignore
                            group.patchValue(item);
                            console.log(item)
                            return group;
                                      }):
                        this.settedobjets.map((item,index) => {
                          const group4 = this.createammount6();
                          console.log(item)
                          //@ts-ignore
                          group4.patchValue(
                            {
                              objet:item,
                              cpte:'',
                            }
                          );
                          return group4;
                        })
                        )
          });
          user.paramcomptable[0]?this.settednumeros.map((el,index) => {
            let ammounts5 = this.releveform.get('ammounts5') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesmanuelcompte.find(element=>element.numerocompte==el.num)==undefined)
            {
              const group = this.createammount5();
              //@ts-ignore
              group.patchValue(
                {
                  banque:this.banques.length>0?this.banques[index].bq:'',
                  numerocompte:el.num,
                  cpte:'',
                  journalmanuel:'',

                }
              );
ammounts5.push(group)            
}
          }
          ):[];
          user.paramcomptable[0]?this.settedobjets.map((el,index) => {
            let ammounts6 = this.releveform.get('ammounts6') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesmanuelobjets.find(element=>element.objet==el)==undefined)
            {
              console.log(el)

              const group = this.createammount6();
              //@ts-ignore
              group.patchValue(
                {
                  objet:el,
                  cpte:'',
                }
              );
ammounts6.push(group)            
}
          }
          ):[];


          /*this.releveform = this.formBuilder.group({ 
            //@ts-ignore
            journal: [user.paramcomptable[0]?user.paramcomptable[0].journalmanuel:''],
            //@ts-ignore
            ammounts5: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelcompte.map((item,index) => {
              const group = this.createammount5();
              //@ts-ignore
              group.patchValue(item);
              return group;
            }):
            this.settednumeros.map((item,index) => {
              const group = this.createammount5();
              //@ts-ignore
              group.patchValue(
                {
                  numerocompte:item.num,
                  cptedebit:'',
                  cptecredit:''

                }
              );
              return group;
            })
            ),
            //@ts-ignore
ammounts6: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptesmanuelnatures.map((item,index) => {
  const group = this.createammount6();
  //@ts-ignore
  group.patchValue(item);
  return group;
            }):
            this.settednumeros.map((item,index) => {
              const group = this.createammount5();
              //@ts-ignore
              group.patchValue(
                {
                  numerocompte:item.num,
                  cptedebit:'',
                  cptecredit:''

                }
              );
              return group;
            }))
          });
          user.paramcomptable[0]?this.settednaturereleve.map((el,index) => {
            let ammounts6 = this.releveform.get('ammounts6') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesmanuelnatures.find(element=>element.nature==el.natman)==undefined)
            {
              const group = this.createammount6();
              //@ts-ignore
              group.patchValue(
                {
                  nature:el.natman,
                  cptedebit:'',
                  cptecredit:''
                }
              );
ammounts6.push(group)            
}
          }
          ):[];*/


          this.recetteform = this.formBuilder.group({
            //@ts-ignore
            journal: [user.paramcomptable[0]?user.paramcomptable[0].journalrecette:''],
            //@ts-ignore
            cptedt: [user.paramcomptable[0]?user.paramcomptable[0].cptetimbrefiscalrecette:''],
            //@ts-ignore
            cptettc: [user.paramcomptable[0]?user.paramcomptable[0].cptettc:''],
            //@ts-ignore  
            ammounts2: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptestvarecette.map((item,index) => {
              const group2 = this.createammount2();
              //@ts-ignore
              group2.patchValue(item);
              return group2;
            }):[])
          });

          this.achatform = this.formBuilder.group({
            //@ts-ignore
            journal: [user.paramcomptable[0]?user.paramcomptable[0].journalachat:''],
            //@ts-ignore
            cptedt: [user.paramcomptable[0]?user.paramcomptable[0].cptetimbrefiscalachat:''],
            //@ts-ignore
            cptetva: user.paramcomptable[0]?user.paramcomptable[0].comptetvaachat:'',   
                      //@ts-ignore
ammounts3: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].comptesttcachat.map((item,index) => {
  const group3 = this.createammount3();
  //@ts-ignore
  group3.patchValue(item);
  return group3;
            }):
            this.settedfournisseurs.map((item,index) => {
              const group3 = this.createammount3();
              //@ts-ignore
              group3.patchValue(
                {
                  fournisseur:item.four,
                  cptettc:''
                }
              );
              return group3;
            })),
                                  //@ts-ignore
            ammounts11: new FormArray(user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].comptesbeneficiaires.map((item,index) => {
              const group11 = this.createammount11();
              //@ts-ignore
              group11.patchValue(item);
              return group11;
                        }):
                        this.settedbeneficiaires.map((item,index) => {
                          const group11 = this.createammount11();
                          //@ts-ignore
                          group11.patchValue(
                            {
                              beneficiaire:item.four,
                              cpte:''
                            }
                          );
                          return group11;
                        })),
                          //@ts-ignore
            ammounts4: new FormArray(user.paramcomptable[0]?user.paramcomptable[0].compteshtachat.map((item,index) => {
              const group4 = this.createammount4();
              //@ts-ignore
              group4.patchValue(item);
              return group4;
                        }):
                        this.settednatures.map((item,index) => {
                          const group4 = this.createammount4();
                          //@ts-ignore
                          group4.patchValue(
                            {
                              natureachat:item.nat,
                              cpteht:'',
                              cptehtdevise:''
                            }
                          );
                          return group4;
                        })),
                                       //@ts-ignore
            ammounts9: new FormArray(user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?user.paramcomptable[0].comptesreglementachat.map((item,index) => {
              const group5 = this.createammount9();
              //@ts-ignore
              group5.patchValue(item);
              return group5;
                        }):
                        this.settedreglements.map((item,index) => {
                          const group5 = this.createammount9();
                          //@ts-ignore
                          group5.patchValue(
                            {
                              reglement:item.reg,
                              cpte:'',
                              journal:'',

                            }
                          );
                          return group5;
                        }))
          });
          user.paramcomptable[0]?this.settedfournisseurs.map((el,index) => {
            let ammounts3 = this.achatform.get('ammounts3') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesttcachat.find(element=>element.fournisseur==el.four)==undefined)
            {
              const group3 = this.createammount3();
              //@ts-ignore
              group3.patchValue(
                {
                  fournisseur:el.four,
                  cptettc:''
                }
              );
ammounts3.push(group3)
            }
          }
          ):[];
          user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'?this.settedbeneficiaires.map((el,index) => {
            let ammounts11 = this.achatform.get('ammounts11') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesbeneficiaires.find(element=>element.beneficiaire==el.four)==undefined)
            {
              const group11 = this.createammount11();
              //@ts-ignore
              group11.patchValue(
                {
                  beneficiaire:el.four,
                  cpte:''
                }
              );
ammounts11.push(group11)
            }
          }
          ):[];
          user.paramcomptable[0]&&user.activite=='Syndic des copropriétaires'? this.settedreglements.map((el,index) => {
            let ammounts9 = this.achatform.get('ammounts9') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].comptesreglementachat.find(element=>element.reglement==el.reg)==undefined)
            {
              const group6 = this.createammount9();
              //@ts-ignore
              group6.patchValue(
                {
                  reglement:el.reg,
                  cpte:'',
                  journal:''

                }
              );
ammounts9.push(group6)
            }
          }
          ):[];
          user.paramcomptable[0]?this.settednatures.map((el,index) => {
            let ammounts4 = this.achatform.get('ammounts4') as FormArray;
                          //@ts-ignore
            if(user.paramcomptable[0].compteshtachat.find(element=>element.natureachat==el.nat)==undefined)
            {
              const group4 = this.createammount4();
              //@ts-ignore
              group4.patchValue(
                {
                  natureachat:el.nat,
                  cpteht:'',
                  cptehtdevise:''
                }
              );
ammounts4.push(group4)
            }
          }
          ):[];
        }
      )
    

  }
  myFunction3() {
    var checkbox:any = document.getElementById("myCheck3");
    var text2 = document.getElementById("bodycontainer3");
    this.dosspasencorevalide=[]
    let dossnonaffecte1=[]
    let dossnonaffecte2=[]
    let dossnonaffecte3=[]
dossnonaffecte1=((this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte
              //@ts-ignore                                                            

&&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient=='déposé par le client':'')))

dossnonaffecte2=(this.deccomptabilites.filter((deccomptabilite) => deccomptabilite.affecte  
              //@ts-ignore                                                            

&&deccomptabilite.statutadmin.length>0?deccomptabilite.statutadmin[deccomptabilite.statutadmin.length-1].statut!='clôturé':''))

dossnonaffecte3=((this.deccomptabilites.filter((deccomptabilite) => !deccomptabilite.affecte
              //@ts-ignore                                                            

&&deccomptabilite.statutcollab.length>0?deccomptabilite.statutcollab[deccomptabilite.statutcollab.length-1].statutclient!='déposé par le client':''
||!deccomptabilite.affecte&&deccomptabilite.statutcollab.length==0)))

this.dosspasencorevalide=this.dosspasencorevalide.concat(dossnonaffecte1,dossnonaffecte2,dossnonaffecte3)

const sort = new Sort();
this.sorteddosspasencorevalide=this.dosspasencorevalide.sort(sort.startSort('created','asc',''));
    if (checkbox.checked == true)
    {
      text2.style.display = "block";
    } 
    else 
    {
      text2.style.display = "none";
    }
  }
  update1(e)
  {
    this.selected = e.value
    if(this.selected=='Personne Physique')
    {
      this.natureactivites=this.natureactivitesphysique
      this.natsociete=false
      this.userForm.patchValue({
        export: false,
        salaire: false,

      })
      this.tot=false
      this.sal=false
        }
    else  if(this.selected=='Personne Morale')
    {
      this.natureactivites=this.natureactivitesmorale
      this.userForm.get('fiscalmatinchanged').enable()
      this.userForm.get('fiscalmatinchanged2').enable()
    }
    this.userForm.patchValue({
      activitynature: '',
      selectactivitynature:'',
      activity:'',
      selectactivity:'',
      underactivity:'',
      selectunderactivity:'',
      specialite: '',
      sousspecialite: '',
    })
  }
  update0(e)
  {

    this.selected = e.value
/*natureactivites=["Profession Libérale","société","association et syndic","Commerçant","Artisan","Salarié ou Pensionnaire"];*/

    if(this.selected=='association et syndic')
    {
this.natsociete=false
this.activites=this.activitesassociations
this.fiscaltvaassoblis=this.fiscaltvaassoblisassociation
this.userForm.patchValue({
  fiscalmatinchanged:'N',
  fiscalmatinchanged2:'N',
  fiscalmatnumbers:'000',
  export:false,
  salaire:false
})
this.tot=false
this.sal=false
    }
    else if (this.selected=='société'&&this.user.nature=="Personne Morale")
    {
      this.activites=this.activitessociete
      this.natsociete=true
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'M',
        fiscalmatnumbers:'000'
      })
    }
    else if (this.selected=='Profession Libérale')
    {
      this.natsociete=false
      this.activites=this.activitesliberales
      this.fiscaltvaassoblis=this.fiscaltvaassoblisliberale
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'P',
        fiscalmatnumbers:'000'
      })
      this.tot=false
      this.sal=false
    }
    else if (this.selected=='Commerçant')
    {
      this.natsociete=false
      this.activites=this.activitescommercant
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'C',
        fiscalmatnumbers:'000'
      })
    }
    else if (this.selected=='Artisan')
    {
      this.natsociete=false
      this.activites=this.activitesartisan
      this.userForm.patchValue({
        fiscalmatinchanged:'A',
        fiscalmatinchanged2:'C',
        fiscalmatnumbers:'000'
      })
    }

    else{
      this.activites=[]
      this.userForm.get('fiscalmatinchanged').enable()
      this.userForm.get('fiscalmatinchanged2').enable()
      this.natsociete=false
    }
    this.userForm.patchValue({
      activity:'',
      selectactivity:'',
      underactivity:'',
      selectunderactivity:'',
      specialite: '',
      sousspecialite: '',
    })
  }
  update(e)
  {
    this.selected = e.value
   /* activites: any[]=["Médecin","Avocat","Consultant","Expert","Infirmier","Masseur","Physiothérapeute","Ergothérapeute","Psychomotricien",
    "Diététicien","Orthophoniste","Orthoptiste","Sage-femmes","Architectes","Dessinateurs","Géomètres","Notaire","Huissiers notaire (de justice)", "Interprètes",
    "Ingénieurs-conseil","Topographes","Syndic des copropriétaires","Ventes en gros","Ventes en détail","Consommation sur place","Batiment et travaux annexes","Réparation automobiles","Autre"
  ,"Menuiserie","Commerciale","Industrielle","Service"];*/
    if(this.userForm.get('activity').value=='Syndic des copropriétaires')
{
  this.userForm.patchValue({
    fiscalmatinchanged:'N',
    fiscalmatinchanged2:'N',
    fiscalmatnumbers:'000'
  })
}
    if(this.selected=='Avocat')
    {
this.sousactivites=this.sousactivitesavocat
    }
    else if (this.selected=='Médecin')
    {
      this.sousactivites=this.sousactivitesmedecin

    }
    else if (this.selected=='Consommation sur place')
    {
      this.sousactivites=this.sousactivitesconsommationsurplace

    }
    else if (this.selected=='Ventes en détail')
    {
      this.sousactivites=this.sousactivitesventeendetail

    }
    else if (this.selected=='Ventes en gros')
    {
      this.sousactivites=this.sousactivitesventeengros
    }
    else if (this.selected=='Batiment et travaux annexes')
    {
      this.sousactivites=this.sousactivitesbatimentettravauxannexe
    }
    else if (this.selected=='Menuiserie')
    {
      this.sousactivites=this.sousactivitesmenuiserie
    }
    else if (this.selected=='Réparation automobiles')
    {
      this.sousactivites=this.sousactivitesreparationautomobile
    }
    else if (this.selected=='Service')
    {
      this.sousactivites=this.sousactivitesservice

    }
    else if (this.selected=='Commerciale')
    {
      this.sousactivites=this.sousactivitescommerciale

    }
    else if (this.selected=='Industrielle')
    {
      this.sousactivites=this.sousactivitesindustrielle

    }
    else if (this.selected=='services liées aux manifestations et cérémonies')
    {
      this.sousactivites=this.sousactivitesmanifestation

    }
    else{
      this.sousactivites=[]
      this.specialites=[]
      this.sousspecialites=[]
    }
    this.userForm.patchValue({
      underactivity:'',
      selectunderactivity:'',
      specialite: '',
      sousspecialite: '',
    })
  }
  update2(e)
  {
    this.selected = e.value
    
    if(this.selected=='Médecin spécialiste')
    {
this.specialites=this.specialitesmedecinspecialiste
    }
    else   if(this.selected=='Transport pour le compte d\'autrui')
    {
 this.specialites=this.specialitestransport
    }
    else{
      this.specialites=[]
      this.sousspecialites=[]

    }
    this.userForm.patchValue({
      specialite: '',
      sousspecialite: '',
    })
  }
  update3(e)
  {
  }
  sort()
  {
    this.specialites.sort()
    this.sousactivites.sort()
    this.activites.sort()
  }
  onImagePick(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadFiles=this.uploadFiles.filter(e=>e.name!='cif')
    if (file)
    {
      const myRenamedFile = new File([file],'cif', {
        type: "application/pdf",
      });
      this.userForm.patchValue({ image: myRenamedFile });  
      this.userForm.updateValueAndValidity();         
  this.uploadFiles.push(this.userForm.get('image').value);
    } 
    const reader = new FileReader();
    reader.onload = () => {
      if (this.userForm.valid) {
        this.cifUploaded = true;
      } else {
      }
    };
  }
  onImagePick2(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadFiles=this.uploadFiles.filter(e=>e.name!='di')
    if (file)
    {
      const myRenamedFile = new File([file],'di', {
        type: "application/pdf",
      });
      this.userForm.patchValue({ image2: myRenamedFile });  
      this.userForm.updateValueAndValidity();         
  this.uploadFiles.push(this.userForm.get('image2').value);
    } 
    const reader = new FileReader();
    reader.onload = () => {
      if (this.userForm.valid) {
        this.diUploaded = true;
      } else {
      }
    };
  }
  onImagePick5(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadFiles=this.uploadFiles.filter(e=>e.name!='logo')
    if (file)
    {
      const myRenamedFile = new File([file],'logo', {
        type: "application/pdf",
      });
      this.userForm.patchValue({ image5: myRenamedFile });  
      this.userForm.updateValueAndValidity();         
  this.uploadFiles.push(this.userForm.get('image5').value);
    } 
    const reader = new FileReader();
    reader.onload = () => {
      if (this.userForm.valid) {
        this.diUploaded = true;
      } else {
      }
    };
  }
  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      this.uploadEvent = event;
    }
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      //@ts-ignore
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = read(bstr, {
        type: "binary"
      });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      this.exceljsondata2 = utils.sheet_to_json(worksheet, {
        raw: true,
        defval: "",
      });
      this.copform = new FormGroup({                
        ammounts13: new FormArray(this.exceljsondata2.map(item => {
          const group = this.createammount13();
          //@ts-ignore
          group.patchValue(
            {
                        //@ts-ignore
              numero:item.numero,
                        //@ts-ignore
              coproprietaire:item.coproprietaire
            }); 
            return group;
        }))
      })    
    };
    fileReader.readAsArrayBuffer(this.file);
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  reloadPage(): void {
    
    window.location.reload();
    
  }
  check(i:number)
  {

  }
  
  
}