import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { Deccomptabilite } from '../models/dec-comptabilite';
const API_URL_test= 'http://localhost:3002/api/deccomptabilite/';
const API_URL_cloud= 'https://macompta.com.tn:3000/prod/api/deccomptabilite/'
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
@Injectable({ providedIn: 'root' })
export class DeccomptabiliteService {
  public submitted=false;
  dsdeccomptabilitesuser: Deccomptabilite[] = [];
  dsdeccomptabilites: Deccomptabilite[] = []; 
  deccomptabilitespasencorevalide: Deccomptabilite[];
  deccomptabilitespasencoreaffecte: Deccomptabilite[];
  deccomptabilitesencours: Deccomptabilite[];
  deccomptabilitesactif: Deccomptabilite[];
  deccomptabilitescloture: Deccomptabilite[];
  deccomptabilitesnonpaye: Deccomptabilite[];
  deccomptabilitespaye: Deccomptabilite[];
  deccomptabilitesmarquepaye: Deccomptabilite[];
  constructor(private http: HttpClient) { }
  public deccomptabilites: Deccomptabilite[] = [];
  public deccomptabilites$ = new Subject<Deccomptabilite[]>();
  public dsdeccomptabilites$ = new Subject<Deccomptabilite[]>();
  public dsdeccomptabilitesuser$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitespasencorevalide$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitesnonaffecte$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitesencours$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitesactif$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitescloture$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitesnonpaye$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitespaye$ = new Subject<Deccomptabilite[]>();
  public deccomptabilitesmarquepaye$ = new Subject<Deccomptabilite[]>();

  getdeccomptabilites() {
        this.http.get(API_URL_cloud).subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
              this.deccomptabilites = deccomptabilites;
              this.emitdeccomptabilites();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdeccomptabilitepasencorevalide() {
        this.http.get(API_URL_cloud+'/filter/'+'nonvalide/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitespasencorevalide = deccomptabilites;
              this.emitdeccomptabilitenonvalide();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdeccomptabilitepasencoreaffecte() {
        this.http.get(API_URL_cloud+'/filter/'+'nonaffecte/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitespasencoreaffecte = deccomptabilites;
              this.emitdeccomptabilitenonaffecte();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }  
  getdeccomptabiliteencours() {
        this.http.get(API_URL_cloud+'/filter/'+'encours/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitesencours = deccomptabilites;
              this.emitdeccomptabiliteencours();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdeccomptabiliteactif() {
        this.http.get(API_URL_cloud+'/filter/'+'actif/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitesactif = deccomptabilites;
              this.emitdeccomptabiliteactif();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdeccomptabilitecloture() {
        this.http.get(API_URL_cloud+'/filter/'+'cloture/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitescloture = deccomptabilites;
              this.emitdeccomptabilitecloture();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdeccomptabilitenonpaye() {
        this.http.get(API_URL_cloud+'/filter/'+'nonpaye/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitesnonpaye = deccomptabilites;
              this.emitdeccomptabilitenonpaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdeccomptabilitepaye() {
        this.http.get(API_URL_cloud+'/filter/'+'paye/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitespaye = deccomptabilites;
              this.emitdeccomptabilitepaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getdeccomptabilitemarquepaye() {
        this.http.get(API_URL_cloud+'/filter/'+'marquepaye/').subscribe(
          (deccomptabilites: Deccomptabilite[]) => {
            if (deccomptabilites) {
             this.deccomptabilitesmarquepaye = deccomptabilites;
              this.emitdeccomptabilitemarquepaye();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
  getDeccomptabilitereqById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud + id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
  getDeccomptabilitereqByfactureuser(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'factures/'+ id).subscribe(
            (deccomptabilities:Deccomptabilite[]) => {
//              resolve(response);
              if (deccomptabilities) {
                this.dsdeccomptabilitesuser = deccomptabilities;
                this.emitdsdeccomptabilitesuser(); 
              }
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
  getDeccomptabilitereqByfactureuserwithoutsubscription(id: string) {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'factures/'+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
  getDeccomptabilitereqByfacture() {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'facture/all/toute/').subscribe(
            (deccomptabilities:Deccomptabilite[]) => {
//            resolve(response);
              if (deccomptabilities) {
                this.dsdeccomptabilites = deccomptabilities;
                this.emitdsdeccomptabilites();  
              }
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }
  getDeccomptabilitereqByfacturewithoutsubscription() {
        return new Promise((resolve, reject) => {
          this.http.get(API_URL_cloud +'facture/all/toute/').subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error); 
            }
          );
        });
      }   
  emitdeccomptabilites() {
        this.deccomptabilites$.next(this.deccomptabilites);
      }
  emitdsdeccomptabilites() {
  this.dsdeccomptabilites$.next(this.dsdeccomptabilites);
      }
  emitdsdeccomptabilitesuser() {
  this.dsdeccomptabilitesuser$.next(this.dsdeccomptabilitesuser);
      }
  emitdeccomptabilitenonvalide() {
        this.deccomptabilitespasencorevalide$.next(this.deccomptabilitespasencorevalide);
      }
  emitdeccomptabilitenonaffecte() {
        this.deccomptabilitesnonaffecte$.next(this.deccomptabilitespasencoreaffecte);
      }
  emitdeccomptabiliteencours() {
        this.deccomptabilitesencours$.next(this.deccomptabilitesencours);
      }
  emitdeccomptabilitenonpaye() {
        this.deccomptabilitesnonpaye$.next(this.deccomptabilitesnonpaye);
      //  console.log('Service emits: ', this.deccomptabilitesnonpaye);
      }
  emitdeccomptabilitepaye() {
        this.deccomptabilitespaye$.next(this.deccomptabilitespaye);
      }
  emitdeccomptabilitemarquepaye() {
        this.deccomptabilitesmarquepaye$.next(this.deccomptabilitesmarquepaye);
      }
  emitdeccomptabiliteactif() {
        this.deccomptabilitesactif$.next(this.deccomptabilitesactif);
      }
  emitdeccomptabilitecloture() {
        this.deccomptabilitescloture$.next(this.deccomptabilitescloture);
      }
  getdeccomptabilite(userId: string) {
        return new Promise((resolve, reject) => {
          this.http.post(API_URL_cloud  ,{userId}).subscribe(
            (deccomptabilites: Deccomptabilite[]) => {
              if (deccomptabilites) {
                this.deccomptabilites = deccomptabilites;
                this.emitdeccomptabilites();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        });
      } 
 
  geexistenttdeccomptabilite(userId: string,annee:string,mois:string) 
    {
return new Promise((resolve, reject) => {
        this.http.post(API_URL_cloud+'anneemois/',{userId,annee,mois}).subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error); 
          }
        );
      });

      }
  create(deccomptabilite: Deccomptabilite, image3: File[], image5: File[], image6: File[], image8: File[], image9: File[], image11: File[]) {
        return new Promise((resolve, reject) => {
          this.submitted=true
          const deccomptabiliteData = new FormData();
          const filtredautre3=this.filterByValue(deccomptabilite.autre3,'true')
          const filtredautre5=this.filterByValue(deccomptabilite.autre5,'true')
          const filtredautre8=this.filterByValue(deccomptabilite.autre8,'true')
          const filtredautre9=this.filterByValue(deccomptabilite.autre9,'true')
          const filtredautre11=this.filterByValue(deccomptabilite.autre11,'true')

          let autre6filtredpre=[]
          let salariearray=[]
          console.log(deccomptabilite.autre6)
          deccomptabilite.autre6.forEach(item=>
            {
salariearray.push(item.salarie)
            }
          )
          console.log(salariearray)
          salariearray.forEach((element,index) =>
        {
      autre6filtredpre.push(element[index])
        }
      )
      console.log(autre6filtredpre)
          const filtredautre6=this.filterByValue(autre6filtredpre,'true')
console.log(filtredautre6)
          deccomptabiliteData.append('deccomptabilite', JSON.stringify(deccomptabilite));
          
            for (let i = 0; i < image3.length; i++)
            {
              deccomptabiliteData.append('image', image3[i],'t'+filtredautre3[i].type+filtredautre3[i].fournisseur+filtredautre3[i].numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
            }
          
         
            for (let i = 0; i < image5.length; i++)
            {
              deccomptabiliteData.append('image', image5[i],'t'+filtredautre5[i].type+filtredautre5[i].annee+filtredautre5[i].mois); 
            }
          
            for (let i = 0; i < image6.length; i++)
            {
              deccomptabiliteData.append('image', image6[i],'t'+filtredautre6[i].type+filtredautre6[i].matricule+deccomptabilite.mois+deccomptabilite.annee); 
            }
            for (let i = 0; i < image8.length; i++)
            {
              deccomptabiliteData.append('image', image8[i],'t'+filtredautre8[i].type+filtredautre8[i].fournisseur+filtredautre8[i].beneficiaire+filtredautre8[i].numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
            }
            for (let i = 0; i < image9.length; i++)
            {
              deccomptabiliteData.append('image', image9[i],'t'+filtredautre9[i].type+filtredautre9[i].fournisseur+filtredautre9[i].beneficiaire+filtredautre9[i].numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
            }
            for (let i = 0; i < image11.length; i++)
            {
              deccomptabiliteData.append('image', image11[i],'t'+filtredautre11[i].type+filtredautre11[i].fournisseur+filtredautre11[i].beneficiaire+filtredautre11[i].numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
            }
          console.log(deccomptabiliteData)
          
        
          this.http.post(API_URL_cloud+'/createdeccomptabilite/', deccomptabiliteData).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      createwithoutfile(deccomptabilite: Deccomptabilite) {
        return new Promise((resolve, reject) => {
          this.submitted=true

          this.http.post(API_URL_cloud+'/createdeccomptabilitewithout/', deccomptabilite).subscribe(
            (response) => {
              resolve(response);

            },
            (error) => {
              reject(error);
            },
          );
        });
      }
     
     
    
      modify(id: string,deccomptabilite: Deccomptabilite, image3: File[], image5: File[], image6: File[], image8: File[], image9: File[], image11: File[]) {
        return new Promise((resolve, reject) => {
          this.submitted=true

          const deccomptabiliteData = new FormData();
        const filtredautre3=this.filterByValue(deccomptabilite.autre3,'true')
        const filtredautre5=this.filterByValue(deccomptabilite.autre5,'true')
        const filtredautre8=this.filterByValue(deccomptabilite.autre8,'true')
        const filtredautre9=this.filterByValue(deccomptabilite.autre9,'true')
        const filtredautre11=this.filterByValue(deccomptabilite.autre11,'true')

        let autre6filtredpre=[]
        let salariearray=[]
        console.log(deccomptabilite.autre6)
        deccomptabilite.autre6.forEach(item=>
          {
salariearray.push(item.salarie)
          }
        )
        console.log(salariearray)
        salariearray.forEach((element,index) =>
      {
    autre6filtredpre.push(element[index])
      }
    )
    console.log(autre6filtredpre)
        const filtredautre6=this.filterByValue(autre6filtredpre,'true')
    deccomptabiliteData.append('deccomptabilite', JSON.stringify(deccomptabilite));
        
        filtredautre3.forEach((item, index) => 
        {     
          image3.forEach((element, pos) => 
          {  
            if(element.name==item.numeropiece)
{
  deccomptabiliteData.append('image', element,'t'+item.type+item.fournisseur+item.numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
}
          }
          ) 
       })
       filtredautre8.forEach((item, index) => 
        {     
          image8.forEach((element, pos) => 
          {  
            if(element.name==item.numeropiece)
{
  deccomptabiliteData.append('image', element,'t'+item.type+item.fournisseur+item.beneficiaire+item.numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
}
          }
          ) 
       })
       filtredautre9.forEach((item, index) => 
        {     
          image9.forEach((element, pos) => 
          {  
            if(element.name==item.numeropiece)
{
  deccomptabiliteData.append('image', element,'t'+item.type+item.fournisseur+item.beneficiaire+item.numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
}
          }
          ) 
       })
       filtredautre11.forEach((item, index) => 
        {     
          image11.forEach((element, pos) => 
          {  
            if(element.name==item.numeropiece)
{
  deccomptabiliteData.append('image', element,'t'+item.type+item.fournisseur+item.beneficiaire+item.numerofacture+deccomptabilite.mois+deccomptabilite.annee); 
}
          }
          ) 
       })
       filtredautre5.forEach((item, index) => 
        {     
          image5.forEach((element, pos) => 
          {  
            if(element.name==item.numeropiece)
{
  deccomptabiliteData.append('image', element,'t'+item.type+item.annee+item.mois); 
}
          }
          ) 
       })
       filtredautre6.forEach((item, index) => 
        { 
          console.log(image6)    
          image6.forEach((element, pos) => 
          {  
            if(element.name==item.matricule)
{
  deccomptabiliteData.append('image', element,'t'+item.type+item.matricule+deccomptabilite.mois+deccomptabilite.annee); 
}
          }
          ) 
       })       
        console.log(deccomptabilite)
       this.http.put(API_URL_cloud + id, deccomptabiliteData).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      completedeccomptabilitereqById(id: string, deccomptabilite: Deccomptabilite) {
        return new Promise((resolve, reject) => {
          
          this.submitted=true

          
          this.http.put(API_URL_cloud+'modify/'+ id, deccomptabilite).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
    
      deletedeccomptabiliteById(id: string) {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud+ id).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }  
      deletedeccomptabilites() {
        return new Promise((resolve, reject) => {
          this.http.delete(API_URL_cloud).subscribe(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        });
      }
      filterByValue(array, value) {
        if (array !== undefined && value !== undefined) {
          return array.filter((data) => {
            // Convert the data to a JSON string and make it lowercase
            const jsonDataString = JSON.stringify(data).toLowerCase();
            // Make the search value lowercase
            const searchValue = value.toLowerCase();
            // Check if the JSON string contains the search value
            return jsonDataString.indexOf(searchValue) !== -1;
          });
        }
           
       }
       filterByValue2(array, value) {
        if (array !== undefined && value !== undefined) {
          return array.find((data) => {
            // Convert the data to a JSON string and make it lowercase
            const jsonDataString = JSON.stringify(data).toLowerCase();
            // Make the search value lowercase
            const searchValue = value.toLowerCase();
            // Check if the JSON string contains the search value
            return jsonDataString.indexOf(searchValue) !== -1;
          });
        }
           
       }
    }
